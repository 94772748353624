@import '../../styles';


.CenterContent {
  opacity: 0;
  visibility: hidden;
  height: 100%;
  width: 100%;
  position: relative;
  padding: 60px 55px;
  padding-left: 90px;
  text-align: left;
  overflow-y: auto;
  margin-left: 25px;


  .content-block {
    padding-bottom: 60px;
    
    p {
      font-size: 18px;
      color: $color-primary;
      line-height: 1.7em;
      margin-top: 15px;
      
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  h2 {
    color: $color-primary;
  }

  .link {
    font-size: 28px;
    margin-top: 0px;
    padding: 10px 0;
    line-height: 1em;

    .bar {
      bottom: 6px;
    }
  }

}
::-webkit-scrollbar {
  height: 7px;
  width: 7px;
}
::-moz-scrollbar {
  height: 7px;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  background: color-alpha($color-primary, 0.05);
  border: solid 1px #FFF;
  border-radius: 10px;
}
::-moz-scrollbar-thumb {
  background: color-alpha($color-primary, 0.05);
  border: solid 1px #FFF;
  border-radius: 10px;
}

:hover::-webkit-scrollbar-thumb {
  background: color-alpha($color-primary, 0.4);
}
:hover::-moz-scrollbar-thumb {
  background: color-alpha($color-primary, 0.4);
}


.portfolio-item-main {
  position: relative;
  margin-bottom: 30px;
  height: 150px;
  
  .portfolio-item {
    overflow: hidden;
    font-size: 0;
    height: inherit;
    font-size: 0;
    height: 100%;
    


    div{
      height: 100%;
    }
    
    .img {
      height: 100%;
      overflow: hidden;
      font-size: 0;
      display: block;
      overflow: hidden;
    }
    .img:before {
      content: " ";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      
      border: solid 2px $color-primary;
      

      z-index: 2;
    }
  

    .portfolio-content {
      display: none;
      opacity: 0;
      visibility: hidden;
    }
  }

}


#project-p {
  position: fixed;
  top: 0;
  left: 0;
  height: 10px;
  width: 10px;
  background: #FFF;
  opacity: 0;
  visibility: hidden;
  z-index: 99;
  overflow: hidden;
  
  .img{
    position: relative;
    overflow: hidden;
    background: $color-primary;
    font-size: 0;
    height: 100%;
  }
  .img:before {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: solid 2px $color-primary;
    z-index: 2;
  }

  .portfolio-content {
    padding: 45px;
    overflow-y: auto;
    height: 100%;
    display: flex;
    flex-direction: column;

    &:focus {
      outline: none;
    }

    h2 {
      margin: 0;
      font-size: 34px;
      margin-top: 20px;
      font-weight: 400;
      margin-bottom: 0px;
      line-height: 1.3em;
    }

    h4 {
      color: $color-primary;
    }

    .content-image {
      width: 90%;
      display: block;
      border: solid 1px $color-primary;
    }


    p {
      margin: 0;
      margin-top: 15px;
      color: $color-primary;
      color: color-alpha($color-primary, .6);
      line-height: 1.6em;
      font-size: 16px;
      width: 70%;
      text-align: justify;
    }

    .portfolio-video {
      width: 85%;
      position: relative;
      z-index: 5;
      margin-top: 55px;
      margin-bottom: 40px;
      font-size: 0;

      >i {
        position: absolute;
        bottom: 150px;
        right: 100%;
        white-space: nowrap;
        transform: rotate(-90deg);
        transform-origin: 100% 0;
        color: $color-primary;
        opacity: 1;
        padding: 3px 10px;
        padding-bottom: 2px;
        background:$color-primary;
        color: rgba(255, 255, 255, 0.8);
        border-radius: 5px 5px 0 0;


        line-height: 1em;
        font-style: normal;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 10px;
        letter-spacing: 2px;
        margin-right: 13px;
        box-shadow: inset 0 -10px 5px -5px #000;
        z-index: -1;
      }
    }
    .portfolio-video > video {
      border: solid 2px color-alpha($color-primary, 1);
      border-radius: 15px;
      background: #FFF;
      min-height: 150px;
      width: 100%;
    }
    .portfolio-video::before {
      content: " ";
      position: absolute;
      bottom: -28px;
      left: -28px;
      width: 100%;
      height: 100%;
      background: url(../../media/dot.svg);
      background-repeat: repeat;
      background-size: 5px;
      opacity: .3;
      z-index: -2;
    }

  }

}

video {
  max-width: 100%;
}