@import '../../styles';


.SideContent {
  text-align: left;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-width: 240px;
  width: 240px;
  max-width: 240px;

  .inner {
    position: relative;
  }

  .pic {
    height: 150px;
    width: 150px;
    overflow: hidden;
    border-radius: 50%;
    margin-bottom: 40px;
    transition: filter 0.3s ease;
    filter: grayscale(1);

    &:hover {
      filter: none;
    }
  }

  h1 {
    margin: 0;
    font-size: 54px;
    font-weight: 400;
    line-height: 1.1em;
    color: $color-primary;
    padding-bottom: 30px;

    .light {
      opacity: .6;
    }
  }

  p {
    color: color-alpha($color-primary, .6);
    font-size: 18px;
    line-height: 1.7em;
    margin-bottom: 30px;
    overflow: hidden;
  }

  #p-side-heading,
  #p-side-text{
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    visibility: hidden;
    opacity: 0;
  }
}