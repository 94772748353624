@import '../../styles';

* {
  user-select: none;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body {
  overflow: hidden;
}
.Main {
  max-width: 1070px;
  padding: 0 35px;
  height: 100%;
  width: 100%;
  margin: auto;
  display: flex;
}
.link {
  display: inline-block;
  padding: 5px 5px;
  margin: 0;
  color: $color-primary;
  text-align: center;
  text-decoration: none;
  font-size: 22px;
  border-radius: 0px;
  position: relative;
  z-index: 5;
  background: none;
  border: none;
  outline: none !important;
  overflow: hidden;
  
  .bar {
    position: absolute;
    bottom: 5px;
    left: 0;
    width: 100%;
    height: 8px;
    transform: translate3d(0, -4px, 0);
    z-index: -1;
    background: color-alpha($color-primary, .6);
    opacity: 0.32;
  }
}

a {
  color: $color-primary;
  text-decoration: underline;
}