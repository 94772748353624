@import url('https://fonts.googleapis.com/css2?family=Jost:wght@100;300;400;700;900&display=swap');
html, body, #root, .App {
	width: 100%;
	height: 100%;
}
img {
	max-width: 100%;
}
* {
	font-family: inherit;
	cursor: none;
	
	&,
	&::before,
	&::after{
		box-sizing: border-box;
	}
}
body {
	margin: 0;
	font-family: 'Jost', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
