@import "../../styles";

.Cursor{

	position: absolute;
	display: block;
	height: 36px;
	width: 36px;
	top: -18px;
	left: -18px;
	border: solid 1px $color-primary;
	border-radius: 50%;
	z-index: 9999;
	pointer-events: none;

	&.scaled{
		box-shadow: 0 0 0 1px rgba(255, 255, 255, 1);
	}
	
	.dot{
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		transform: scale(.2);
		background: $color-primary;
		border-radius: 50%;
	}

}